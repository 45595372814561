<template>
  <div>
<!--    头部-->
    <div style="display: flex; height: 60px; line-height: 60px; border-bottom: 1px solid #eee">
      <div style="width: 300px; display: flex; padding-left: 30px">
        <div style="width: 60px">
          <img src="../../assets/logo.png" alt="" style="width: 50px; position: relative; top: 5px; right: 0">
        </div>
        <div style="flex: 1; font-size: 24px; font-weight: 700;">Awen学习交流网</div>
      </div>
      <div style="flex: 1">
<!--        导航菜单-->
<!--        <ul style="list-style: none; background-color: 	#98FB98; ">-->
<!--          <li class="item">-->
<!--            <el-dropdown size="medium">-->
<!--              <span>更多菜单<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i></span>-->
<!--              <el-dropdown-menu slot="dropdown">-->
<!--                <el-dropdown-item icon="el-icon-plus">黄金糕</el-dropdown-item>-->
<!--                <el-dropdown-item>狮子头</el-dropdown-item>-->
<!--                <el-dropdown-item>螺蛳粉</el-dropdown-item>-->
<!--                <el-dropdown-item>双皮奶</el-dropdown-item>-->
<!--                <el-dropdown-item>蚵仔煎</el-dropdown-item>-->
<!--              </el-dropdown-menu>-->
<!--            </el-dropdown>-->
<!--          </li>-->
<!--          <li class="item"><a href="/">菜单2</a></li>-->
<!--          <li class="item"><a href="/">菜单3</a></li>-->
<!--          <li class="item"><a href="/">菜单4</a></li>-->
<!--        </ul>-->

        <el-menu :default-active="'1'" class="el-menu-demo" mode="horizontal" router>
          <el-menu-item index="/front/home">首页</el-menu-item>
          <el-menu-item index="/front/video">网课视频集</el-menu-item>
          <el-menu-item index="/front/article">文章列表</el-menu-item>
          <el-submenu index="2">
            <template slot="title">我的工作台</template>
            <el-menu-item index="/front/item1">选项1</el-menu-item>
            <el-menu-item index="2-2">选项2</el-menu-item>
            <el-menu-item index="2-3">选项3</el-menu-item>
            <el-submenu index="2-4">
              <template slot="title">选项4</template>
              <el-menu-item index="2-4-1">选项1</el-menu-item>
              <el-menu-item index="2-4-2">选项2</el-menu-item>
              <el-menu-item index="2-4-3">选项3</el-menu-item>
            </el-submenu>
          </el-submenu>
          <el-menu-item index="3" disabled>消息中心</el-menu-item>
<!--          <el-menu-item index="4"><a href="https://www.ele.me" target="_blank">订单管理</a></el-menu-item>-->
        </el-menu>
      </div>
      <div style="width: 200px">
        <div v-if="!user.username" style="text-align: right; padding-right: 30px">
          <el-button @click="$router.push('/login')">登录</el-button>
          <el-button @click="$router.push('/register')">注册</el-button>
        </div>
        <div v-else>
          <el-dropdown style="width: 150px; cursor: pointer; text-align: right">
            <div style="display: inline-block">
              <img :src="user.avatarUrl" alt=""
                   style="width: 30px; border-radius: 50%; position: relative; top: 10px; right: 5px">
              <span>{{ user.nickname }}</span><i class="el-icon-arrow-down" style="margin-left: 5px"></i>
            </div>
            <el-dropdown-menu slot="dropdown" style="width: 100px; text-align: center">
              <el-dropdown-item style="font-size: 14px; padding: 5px 0">
                <router-link to="/front/password">修改密码</router-link>
              </el-dropdown-item>
              <el-dropdown-item style="font-size: 14px; padding: 5px 0">
                <router-link to="/front/person">个人信息</router-link>
              </el-dropdown-item>
              <el-dropdown-item style="font-size: 14px; padding: 5px 0">
                <span style="text-decoration: none" @click="logout">退出</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>

    <div style="width: 100%; margin: 0 auto; max-height: calc(100vh - 180px); overflow-y: auto;">
      <router-view />
    </div>
    <!--  网页的footer信息，高100px  -->
    <div style="height: 100px;position: fixed;top:calc(100vh - 120px);width: 100%;">
      <div style="height: 3px; background: #ddd; margin: 3px 0"/>
      <div style="padding: 0 5%">
      <div style="display: flex;flex-direction: row;">
        <div style="display: flex;flex-direction: column; justify-content: space-around; width: 500px;">
          <b>链  接</b>
          <div><span style="padding-top: 5px">在线考试：</span>
            <el-link type="primary" href="https://www.yz-exam.cn:12345" target="_blank">https://www.yz-exam.cn:12345</el-link>
          </div>
          <div><span>装备管理：</span>
            <el-link type="primary" href="http://localhost:8081/front" target="_blank">http://www.yz-exam.cn:8081</el-link>
          </div>
        </div>
        <div style="display: flex;flex-direction: column; justify-content: space-around; width: 369px;">
          <!--          <b style="padding-bottom: 5px">微信小程序</b>-->
          <div style="display: flex;flex-direction: row;">
            <div style="display: flex;flex-direction: column;width: 50%">
              <el-image :src="require('@/assets/在线考试.jpg')" style="width: 80px; height: 80px"></el-image>
              <div style="font-size: 12px">在线考试小程序</div>
            </div>
            <div style="display: flex;flex-direction: column;width: 50%">
              <el-image :src="require('@/assets/环境监控.jpg')" style="width: 80px; height: 80px"></el-image>
              <div style="font-size: 12px">环境监控小程序</div>
            </div>
          </div>
        </div>
        <div style="display: flex;flex-direction: column;width: 160px;">
          <el-image :src="require('@/assets/星球-小.png')" style="width: 80px; height: 80px"></el-image>
          <div style="font-size: 12px">微信公众号</div>
        </div>
        <div style="display: flex;flex-direction: column;width:100%;align-items: center;justify-content: space-around;">
          <div>© 2024 yz_awen ®</div>
          <el-link type="primary" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">桂ICP备2023000767号-1</el-link>
          <div style="display: flex;flex-direction: row">
            <el-image :src="require('@/assets/安备.png')" style="width: 20px; height: 20px"></el-image>
            <el-link type="primary" href="https://beian.mps.gov.cn/#/query/webSearch?code=45128102451368" target="_blank">桂公网安备45128102451368号</el-link>
            <div></div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Front",
  data() {
    return {
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
    }
  },
  created() {

  },
  methods: {
    logout() {
      this.$store.commit("logout")
      this.$message.success("退出成功")
    }
  }
}
</script>

<style>
.item{
  display: inline-block;
  width: 100px;

  text-align: center;
  cursor: pointer
}
.item a {
  color: 	#1E90FF;
}
.item:hover{
  background-color: 	LightPink;
}
</style>
